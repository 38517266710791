import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyCIY5cStbeW1kSTT82AdGUsZxDrG1XJqDE",
    authDomain: "fartorart-ideas.firebaseapp.com",
    databaseURL: "https://fartorart-ideas.firebaseio.com",
    projectId: "fartorart-ideas",
    storageBucket: "fartorart-ideas.appspot.com",
    messagingSenderId: "170727763886",
    appId: "1:170727763886:web:e6837c74f381aaf3"
  };

firebase.initializeApp(config);

export default firebase;
